
.app-input,
.app-btn {
	display: block;
	border: solid 0px transparent;
	outline: none;
	-webkit-tap-highlight-color: transparent;
	touch-action: manipulation;
	background: #202020;
	color: #a0a0a0;
	vertical-align: middle;
	margin: 0;
	padding: 0;
	line-height: 100%;
	transition: all ease-in-out 0.1s;
}

.app-input { width: 100%; padding: 12px; }
.app-input-sm { padding: 4px; }
.app-message-input { height: 48px; }

.app-btn { cursor: pointer; color: #808080; }
.app-send { height: 48px; color: #2090f0; padding: 12px; }
.app-voidboi {
	height: 48px; width: 48px; color: #f0f0f0;
	border-radius: 50%;
	background-position: center;
	transition: background-color 0.5s;
	&:active {
		background: red radial-gradient(circle, transparent 1%, white 1%) center/15000%;
		transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	}
}

.app-input:disabled,
.app-btn:disabled,
.app-input:disabled:hover, .app-input:disabled:active, .app-input:disabled:focus,
.app-btn:disabled:hover, .app-btn:disabled:active, .app-btn:disabled:focus {
	background: #202020;
	color: #606060;
}

.app-input:hover, .app-input:active, .app-input:focus { color: #f0f0f0; }
.app-btn:hover, .app-btn:active, .app-btn:focus { color: #f0f0f0; }
.app-send:hover, .app-send:active, .app-send:focus { color: #80c0ff; }

/* .app-send::before { content: ''; } */

.app-btn-adornment {
	background: #202020;
}

.app-action-tray {
	background: #202020;
}

// dialog choices
.app-choice-bar-container {

}
.app-choice-bar {
	text-align: center;
	padding: 5px;
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s, opacity 0.5s linear;
	position: absolute;
	&--visible {
		position: relative;
		visibility: visible;
		opacity: 1;
	}
}
.app-choice-option {
	margin: 5px;
	font-size: 1rem;
	width: 33%; // hacky, assumes we'll only ever have 3 options max per "page"
	min-height: 50px;
	padding: 5px;
	border: 1px solid #f0f0f0;
	border-radius: 8px;
	cursor: pointer;

	&:hover {
		border-color: red;
	}
	&:focus {
		border-color: red;
	}
	&:focus:after {
		border-color: red;
	}
	&:active {
		background: red;
	}
}

.app-choice-option [role=tab]:focus {
	border-color: red;
}

.app-choice-option [role=tab]:focus:after {
	border-color: red;
}

.speech-container {
	position: absolute;
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s, opacity 0.5s linear;
	&--visible {
		overflow: visible;
		position: relative;
		visibility: visible;
		opacity: 1;
	}
}

p.speech {
	color: black;
	margin-left: 10px;
  width: 200px;
  text-align: center;
  line-height: 50px;
  background-color: #fff;
  border-radius: 10px;
	position: inherit;
}

p.speech:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: 32px;
  top: 50px;
  border: 15px solid;
  border-color: #fff transparent transparent #fff;
}
