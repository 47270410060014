
input[type="checkbox"] {
	cursor: pointer;
	display: block;
	position: relative;
	transform: translate(-1000px, -1000px);
	outline: none;
	padding: 0;
	margin: 0;
	width: 28px;
	height: 16px;

	&::before, &::after {
		position: absolute;
		top: 0; left: 0;
		transform: translate(1000px, 1000px);
		content: '';
		display: block;
	}

	&::before {
		width: 26px;
		height: 14px;
		border-radius: 0.5em;
		background-color: #101010;
		border: solid 1px #404040;
	}
	&::after {
		top: 2px;
		left: 2px;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background-color: #808080;
		transition-property: background-color, left;
		transition-duration: 0.25s;
		transition-timing-function: ease-in-out;
	}

	&:checked::after {
		left: 14px;
		background-color: #0080ff;
	}
}
