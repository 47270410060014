
/*.flex { display: flex; }
.flex.h { flex-direction: row; }
.flex.v { flex-direction: column; }
.flex > .grow { flex-grow: 1; position: relative; }
.flex > .grow > .flex-grow-inner { position: absolute; width: 100%; height: 100%; }*/

.flex { display: flex; }
.flex.h { flex-direction: row; }
.flex.v { flex-direction: column; }
.flex > .grow {
	flex-grow: 1;
	min-width: 0;
	min-height: 0;

	&.grow-1 { flex-grow: 1; }
	&.grow-2 { flex-grow: 2; }
	&.grow-3 { flex-grow: 3; }

	& > .flex-grow-inner {
		width: 100%;
		height: 100%;
	}
}

.flex-center { align-items: center; justify-content: center; }

.fullsize { width: 100%; height: 100%; }
.fullwidth { width: 100%; }
.fullheight { height: 100%; }

.scroll-y { overflow-y: scroll; }

@import "./common/_drawer.scss";
