
html, body {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

body {
	/*--hue: 250;
	--sat: 15%;
	background-image: radial-gradient(circle at 50% -300%,
		#000000 0%,
		hsl(var(--hue), var(--sat), 1%) 92%,
		hsl(var(--hue), var(--sat), 30%) 92.04%,
		hsl(var(--hue), var(--sat), 5%) 92.12%,
		hsl(var(--hue), var(--sat), 1%) 94.5%,
		#000000 100%);*/
	background-color: #000000;
	color: #e0e0e0;
	font-family: system-ui, Calibri, Arial;
	font-size: 18px;
}
#root {
	width: 100%;
	height: 100%;
}

h1, h2, h3, h4, h5 { font-weight: normal; color: #808080; margin: 10px; }
h5 { font-size: 10px; font-weight: bold; }
h4 { font-size: 14px; font-weight: bold; }
h3 { font-size: 18px; }
h2 { font-size: 22px; }
h1 { font-size: 26px; }

.app-tag {
	font-size: 18px;
	background-color: #202020;
	margin-top: 1px;
	&-name { padding: 4px; }
}

.app-tagstrip {
	font-size: 16px;
	padding: 4px;
	display: flex;
	flex-direction: row;
	background: #101010;

	& > div {
		&:first-child { margin-left: 0px; }
		margin-left: 8px;
		padding: 0px 8px;
		border: solid 1px #606060;
		color: #808080;
		border-radius: 1em;
	}
}

.app-content {
	display: flex;
	flex-direction: column-reverse;
	overflow-x: hidden;
	/*justify-content: flex-end;*/
}

@import "_input.scss";
@import "_toggle.scss";
@import "_message.scss";
