
html, html * { box-sizing: border-box; }

html, body {
	padding: 0;
	margin: 0;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

input, button, table, td, th, a { font-family: inherit; font-size: inherit; color: inherit; }

@import "./common/_common.scss";
@import "./app/_app.scss";
