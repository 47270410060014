
@keyframes FadeOut {
	0% { opacity: 1; }
	50% { opacity: 1; }
	100% { opacity: 0; }
}

@keyframes PopIn {
	0% { transform: translate(-50%, 0) scale(0, 1.1); }
	100% { transform: translate(0, 0) scale(1, 1); }
}

$pulse-size: -8px;

@keyframes GrowFadeOut {
	--size: -10px;
	0% { top: 0; right: 0; bottom: 0; left: 0; opacity: 1; }
	100% { top: $pulse-size; right: $pulse-size; bottom: $pulse-size; left: $pulse-size; opacity: 0; }
}

.app-message {
	&-wrapper {
		animation-name: FadeOut;
		animation-timing-function: ease-in;
		animation-delay: 0.25s;
		animation-fill-mode: forwards;
	}

	&-wrapper:last-child > .app-message-box { margin-top: 8px; }

	&-box {
		position: relative;
		margin: 0 8px 8px 8px;
		overflow: visible;

		&.mine {
			margin-left: 32px;
			& .app-message-bg { background-color: #303030; }
		}
		&:not(.mine) {
			margin-right: 32px;
		}
	}

	&-bg-back { z-index: 1; }
	&-bg { z-index: 2; }
	&-text-back { z-index: 3; }
	&-text { z-index: 4; }
	&-text-front { z-index: 5; }

	&-bg-back,
	&-text-back,
	&-text-front {
		position: absolute;
		top: 0; right: 0; bottom: 0; left: 0;
		overflow: visible;
	}

	&-bg {
		position: relative;
		padding: 12px;
		border-radius: 8px;
		background: #202020;
		color: #e0e0e0;

		animation: PopIn 0.25s ease-out 0s;
		animation-fill-mode: forwards;
	}

	&-text {
		position: relative;
		display: inline-block;
		white-space: pre-wrap;
	}

	&-pulse {
		position: absolute;
		top: 0; right: 0; bottom: 0; left: 0;
		border-radius: 8px;
		box-shadow: 0 0 4px 9px rgba(255,255,255,0.5);
		transform-origin: center;
		animation: GrowFadeOut 0.25s ease-out 0s;
		animation-fill-mode: forwards;
	}
}
