
.drawer {
	position: fixed;
	z-index: 100;
	top: 0; bottom: 0;
	left: -100%;
	min-width: 200px;
	transition: left 0.5s ease-out;
}

.drawer.visible {
	left: 0;
}

.drawer-backdrop {
	pointer-events: none;
	background-color: transparent;
	position: fixed;
	top: 0; right: -100%; bottom: -100%; left: 0;
	z-index: 100;
	transition: background-color 0.25s ease-in-out;
}

.drawer.visible .drawer-backdrop {
	pointer-events: all;
	right: 0; bottom: 0;
	background-color: #000000a0;
}

.drawer-bg {
	position: relative;
	z-index: 101;
	background-color: #101010;
	border-right: solid 1px #303030;
	box-shadow: 0 0 12px 2px rgba(0,0,0,0.75);
	height: 100%;
}
